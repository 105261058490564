import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import PrimeVue from "primevue/config";
import App from "./App.vue";

import "@/assets/css/main.css";

const messages = {
  russian: {
    message: {
      requirementsStep: "Требования PHP",
      checkPermissionInstallStep: "Проверка базы данных",
      downloadStep: "???",
      setLanguage: "Выберите язык",
      extension: "Расширение",
      result: "Результат",
      extensionName: 'PHP расширение "{0}"',
      extensionEnabled: "Включено",
      extensionDisabled: "Выключено",
      settingName: 'PHP настройка "{0}"',
      settingMemoryLimitRecommend: "Рекомендуется 256MБ +",
      seconds: "секунд",
      settingMaxExecutionTimeRecommend: "Рекомендуется 300+ секунд",
      nextStep: "Следующий шаг",
      checkStep: "Проверить",
      dbHostname: "Имя хоста",
      dbUser: "Имя пользователя",
      dbPassword: "Пароль",
      dbName: "Имя базы данных",
      sql_collate_error:
        "Пожалуйста, установите стандартную кодировку базы данных utf8mb4_unicode_ci",
      sql_json_error:
        "Ваша база данных не поддерживает работу с JSON. Для продолжения установки обновите версию базу данных",
      finish1:
        "Поздравляем, Ваш сервер соответствует требованиям Perfectum CRM+ERP",
      finish2:
        "Вы можете скачать архив Perfectum CRM+ERP и приступить к установке",
      warningMessage1:
        "К сожалению текущий сервер не соответствует требованиям для установки Perfectum CRM+ERP.",
      warningMessage2:
        "Для получения консультации просьба обратиться в техническую поддержку:",
      warningMessage3: `- Личный кабинет клиента`,
      warningMessage4: "- Через сайт",
      warningMessage5: "- Viber",
      warningMessage6: "- Telegram",
    },
  },
  english: {
    message: {
      requirementsStep: "PHP requirements",
      checkPermissionInstallStep: "Checking database",
      downloadStep: "???",
      setLanguage: "Select language",
      extension: "Extension",
      result: "Result",
      extensionName: 'PHP Extension "{0}"',
      extensionEnabled: "Enabled",
      extensionDisabled: "Disabled",
      settingName: 'PHP setting "{0}"',
      settingMemoryLimitRecommend: "256MB + recommended",
      seconds: "seconds",
      settingMaxExecutionTimeRecommend: "300+ seconds recommended",
      nextStep: "Next step",
      checkStep: "Check",
      dbHostname: "Hostname",
      dbUser: "Username",
      dbPassword: "Password",
      dbName: "Database name",
      sql_collate_error:
        "Please set the default database encoding to utf8mb4_unicode_ci",
      sql_json_error:
        "Your database does not support JSON. To continue installation, update the version of the database",
      finish1:
        "Congratulations, your server meets the requirements of Perfectum CRM+ERP",
      finish2:
        "You can download the Perfectum CRM+ERP archive and proceed with the installation",
      warningMessage1:
        "Unfortunately, the current server is not compatible with the installation of Perfectum CRM+ERP.",
      warningMessage2:
        "For further consultation, go back to technical support:",
      warningMessage3: `- Client's personal account`,
      warningMessage4: "- Через сайт",
      warningMessage5: "- Viber",
      warningMessage6: "- Telegram",
    },
  },
  ukrainian: {
    message: {
      requirementsStep: "Вимоги PHP",
      checkPermissionInstallStep: "Перевірка бази данних",
      downloadStep: "???",
      setLanguage: "Виберіть мову",
      extension: "Розширення",
      result: "Результат",
      extensionName: 'PHP розширення "{0}"',
      extensionEnabled: "Включено",
      extensionDisabled: "Не ввімкнено",
      settingName: 'PHP налаштування "{0}"',
      settingMemoryLimitRecommend: "Рекомендовано 256МБ +",
      seconds: "секунд",
      settingMaxExecutionTimeRecommend: "Рекомендовано 300+ секунд",
      nextStep: "Наступний крок",
      checkStep: "Перевірити",
      dbHostname: "Ім'я хоста",
      dbUser: "Ім'я користувача",
      dbPassword: "Пароль",
      dbName: "Ім'я бази данних",
      sql_collate_error:
        "Будь ласка, встановіть стандартне кодування бази даних utf8mb4_unicode_ci",
      sql_json_error:
        "Ваша база даних не підтримує роботу з JSON. Для продовження установки обновіть версію базу даних",
      finish1: "Вітаємо, Ваш сервер відповідає вимогам Perfectum CRM+ERP",
      finish2:
        "Ви можете завантажити архів Perfectum CRM+ERP та приступити до встановлення",
      warningMessage1:
        "На жаль, поточний сервер не відповідає вимогам для встановлення Perfectum CRM+ERP.",
      warningMessage2:
        "Для отримання консультації прохання звернутися до технічної підтримки:",
      warningMessage3: `- Особистий кабінет клієнта`,
      warningMessage4: "- Через сайт",
      warningMessage5: "- Viber",
      warningMessage6: "- Telegram",
    },
  },
};

const i18n = createI18n({
  locale: "russian",
  fallbackLocale: "english",
  messages,
});

createApp(App).use(i18n).use(PrimeVue).mount("#app");
