<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="logo">
          <img src="./assets/images/logo_dark.png" alt="logo" />
        </div>
        <div class="install-row install-steps">
          <install-step
            :name="$t(`message.requirementsStep`, language)"
            :class="{
              'bg-not-passed': step < 1,
              'bg-default': step === 1,
              'bg-success': step > 1,
            }"
          />
          <install-step
            :name="$t(`message.checkPermissionInstallStep`, language)"
            :class="{
              'bg-default': step === 2,
              'bg-success': step > 2,
              'bg-not-passed': step < 2,
            }"
          />
          <install-step
            :name="$t(`message.result`, language)"
            class="finish"
            :class="{
              'bg-default': step === 3,
              'bg-not-passed': step < 3,
            }"
          />
        </div>
        <div class="install-row install-steps">
          <language-selector v-model:language="language" />
        </div>

        <div class="install-row install-steps">
          <step-one
            v-if="step === 1"
            v-model:step="step"
            :language="language"
            :api-url="apiUrl"
          />
          <step-two
            v-if="step === 2"
            v-model:step="step"
            :language="language"
            :api-url="apiUrl"
          />
          <step-three v-if="step === 3" :language="language" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import installStep from "@/components/installStep";
import languageSelector from "@/components/languageSelector";
import stepOne from "@/components/stepOne";
import stepTwo from "@/components/stepTwo";
import stepThree from "@/components/stepThree";

export default {
  name: "App",
  components: {
    installStep,
    languageSelector,
    stepOne,
    stepTwo,
    stepThree,
  },
  data() {
    return {
      language: "russian",
      step: 1,
      apiUrl: "",
    };
  },
};
</script>

<style>
body {
  background: #f1f1f1;
}

h3 {
  margin-top: 7px;
  font-size: 18px;
}

.install-row {
  border: 1px solid #e4e5e7;
  border-radius: 3px;
  background: #fff;
  padding: 15px;
  box-shadow: 0px 2px 4px #d6d6d6;
  display: inline-block;
  width: 100%;
}

.install-row.install-steps {
  margin-bottom: 15px;
  box-shadow: 0px 0px 1px #d6d6d6;
}

.logo {
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 15px;
  display: inline-block;
  width: 100%;
}

.logo img {
  display: block;
  margin: 0 auto;
}

.control-label {
  font-size: 13px;
  font-weight: 600;
}

.padding-10 {
  padding: 10px;
}

.mbot15 {
  margin-bottom: 15px;
}

.bg-default {
  background: #03a9f4;
  border: 1px solid #03a9f4;
  color: #fff;
}

.bg-success {
  border: 1px solid #dff0d8;
}

.bg-not-passed {
  border: 1px solid #f1f1f1;
  border-radius: 2px;
}

.bg-not-passed {
  border-right: 0px;
}

.bg-not-passed.finish {
  border-right: 1px solid #f1f1f1 !important;
}

.bg-not-passed h5 {
  font-weight: normal;
  color: #6b6b6b;
}

.form-control {
  box-shadow: none;
}

.bold {
  font-weight: 600;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 33%;
  float: left;
}

b {
  font-weight: 600;
}

.bootstrap-select .btn-default {
  background: #fff !important;
  border: 1px solid #d6d6d6 !important;
  box-shadow: none;
  color: #494949 !important;
  padding: 6px 12px;
}

.p-inputtext,
.p-datatable-thead,
.p-datatable-tbody,
.p-dropdown-items-wrapper,
.p-button-label {
  font-size: 14px;
}

.p-password,
.p-password-input {
  width: 100% !important;
}

.p-password .p-password-input {
  padding: 6px 12px !important;
}

.p-field-checkbox,
.p-field-radiobutton {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
}

.p-field-checkbox > label,
.p-field-radiobutton > label {
  margin-left: 0.5rem;
  line-height: 1;
}
</style>
