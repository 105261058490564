<template>
  <div class="text-center">
    {{ $t("message.finish1", language) }}
  </div>
  <div class="text-center">
    {{ $t("message.finish2", language) }}
  </div>
</template>

<script>
export default {
  name: "stepThree",
  props: {
    language: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
