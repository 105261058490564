<template>
  <Message severity="error" v-if="message.length" :closable="false">
    {{ message }}
  </Message>
  <div class="p-field">
    <label for="dbHostname">{{ $t("message.dbHostname", language) }}</label>
    <InputText
      id="dbHostname"
      type="text"
      style="width: 100%; margin-bottom: 20px"
      v-model="dbHostname"
    />
    <label for="dbUser">{{ $t("message.dbUser", language) }}</label>
    <InputText
      id="dbUser"
      type="text"
      style="width: 100%; margin-bottom: 20px"
      v-model="dbUser"
    />
    <label for="dbPassword">{{ $t("message.dbPassword", language) }}</label>
    <Password
      :feedback="false"
      :toggleMask="true"
      id="dbPassword"
      style="width: 100%; margin-bottom: 20px"
      v-model="dbPassword"
    />
    <label for="dbName">{{ $t("message.dbName", language) }}</label>
    <InputText
      id="dbName"
      type="text"
      style="width: 100%; margin-bottom: 20px"
      v-model="dbName"
    />
  </div>
  <warning :language="language" v-if="message.length" />
  <div class="text-center" style="margin-top: 20px">
    <Button
      :label="$t(`message.checkStep`, language)"
      class="p-button-raised p-button-success"
      :disabled="!allowNextStep"
      @click="checkDatabase"
      :loading="loading"
    />
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Message from "primevue/message";
import Password from "primevue/password";
import warning from "./warning";

export default {
  name: "stepTwo",
  components: {
    InputText,
    Button,
    Message,
    Password,
    warning,
  },
  props: {
    language: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
  },
  emits: ["update:step"],
  data() {
    return {
      message: "",
      dbHostname: "",
      dbUser: "",
      dbPassword: "",
      dbName: "",
      loading: false,
    };
  },
  computed: {
    allowNextStep() {
      return (
        this.dbHostname.length &&
        this.dbUser.length &&
        this.dbPassword.length &&
        this.dbName.length
      );
    },
  },
  methods: {
    checkDatabase() {
      this.loading = true;

      const data = {
        hostname: this.dbHostname,
        user: this.dbUser,
        password: this.dbPassword,
        name: this.dbName,
      };
      fetch(`${this.apiUrl}/api.php?command=stepTwo`, {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((data) => data.json())
        .then((json) => {
          if (json.database.status) {
            this.$emit("update:step", 3);
            this.message = "";
          } else {
            switch (json.database.error) {
              case "COLLATE":
                this.message = this.$t(`message.sql_collate_error`);
                break;
              case "JSON":
                this.message = this.$t(`message.sql_json_error`);
                break;
              default:
                this.message = json.database.error;
            }
          }

          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.sizes .p-inputtext {
  display: block;
  margin-bottom: 0.5rem;
}

.sizes:last-child {
  margin-bottom: 0;
}

.p-field * {
  display: block;
}
</style>
