<template>
  <div class="alert alert-danger" style="margin-bottom: 0px">
    <p class="text-center">{{ $t("message.warningMessage1", language) }}</p>
    <p class="text-center">{{ $t("message.warningMessage2", language) }}</p>
    <p class="text-center">
      <a href="https://crm.perfectum.one/authentication/login" target="_blank">
        {{ $t("message.warningMessage3", language) }}
      </a>
    </p>
    <p class="text-center">
      <a href="https://perfectum.ua/support" target="_blank">
        {{ $t("message.warningMessage4", language) }}
      </a>
    </p>
    <p class="text-center">
      <a href="viber://pa?chatURI=perfectumua" target="_blank">
        {{ $t("message.warningMessage5", language) }}
      </a>
    </p>
    <p class="text-center">
      <a href="https://t.me/perfectumua_bot" target="_blank">
        {{ $t("message.warningMessage6", language) }}
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "warning",
  props: {
    language: String,
  },
};
</script>
